import { useLayoutEffect, useState } from "react";
import { use100vh } from "react-div-100vh";

export const useViewPortHeight = ({ update = false } = {}) => {
  const [height, setHeight] = useState();
  const viewport = use100vh();

  useLayoutEffect(() => {
    if (!update && !height) {
      setHeight(viewport);
    }
    if (update) {
      setHeight(viewport);
    }
  }, [height, viewport, update]);

  return height;
};
