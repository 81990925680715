import React, { useLayoutEffect } from "react";
import { ParallaxDestinationCard } from "components/Design/Card/ParallaxDestinationCard";
import { DestinationHoverCard } from "components/Design/Card/DestinationHoverCard";
import { graphql } from "gatsby";
import { motion } from "framer-motion";
import { FadingCarousel } from "components/Design/Carousel/FadingCarousel";
import { SplashScreen } from "components/SplashScreen";
import { SeoBlock } from "components/SeoBlock";
import { transformObject } from "utils/transformv4";

const IndexPage = ({ data }) => {
  const {
    cms: { page },
  } = data;

  const { blocks } = transformObject(page);

  const bannerCarousel = blocks.find(
    (b) => b.__typename === "Cms_ComponentPageBannerCarousel"
  );
  const cards = blocks.find((b) => b.__typename === "Cms_ComponentPageCards");
  const seoContent = blocks.find(
    (b) => b.__typename === "Cms_ComponentPageSeo"
  );

  useLayoutEffect(() => {
    if (sessionStorage.getItem("@@scroll|/|initial") === "0") {
      window.scrollTo({ top: 0 });
    }
  }, []);

  return (
    <div className="relative">
      <SeoBlock
        title={seoContent.meta_title}
        description={seoContent.meta_description}
        imageUrl={seoContent.open_graph_image?.cdnURL}
      />
      <SplashScreen />
      <motion.div className="grid grid-cols-6 lg:grid-cols-12" key={"content"}>
        <div className="sticky top-0 col-span-full">
          <FadingCarousel images={bannerCarousel.list} />
        </div>
        {cards.list.map(({ cta_link, desktop, mobile, title, description }) => (
          <div className="col-span-6 snap-start snap-normal lg:snap-none lg:snap-align-none">
            <ParallaxDestinationCard image={{ desktop, mobile }}>
              <DestinationHoverCard
                title={title}
                description={description}
                link={cta_link}
              />
            </ParallaxDestinationCard>
          </div>
        ))}
        <footer className="flex items-center justify-between px-48 py-31 bg-[#231F20] z-30 col-span-6 lg:col-span-12 flex-col lg:flex-row gap-4 lg:gap-0">
          <p className="text-gray-dark text-14 leading-22 tracking-2 lg:ml-auto">
            © {new Date().getFullYear()} AYANA Estate. All Rights Reserved
          </p>
        </footer>
      </motion.div>
    </div>
  );
};

export default IndexPage;

export const query = graphql`
  query AyanaLandingPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    cms {
      page(id: 376) {
        data {
          id
          attributes {
            slug
            layout
            blocks {
              __typename
              ... on Cms_ComponentPageBannerCarousel {
                id
                title
                subtitle
                bannerCarouselIcon: icon
                list {
                  id
                  desktop {
                    ...CmsUploadFileEntityResponse
                  }
                  mobile {
                    ...CmsUploadFileEntityResponse
                  }
                  sequence
                }
              }
              ... on Cms_ComponentPageCards {
                id
                icon
                title
                cardsTheme: theme
                description
                cta_link
                cta_text
                reference
                list {
                  id
                  title
                  description
                  cta_link
                  mobile {
                    ...CmsUploadFileEntityResponse
                  }
                  desktop {
                    ...CmsUploadFileEntityResponse
                  }
                }
              }
              ... on Cms_ComponentPageSeo {
                id
                meta_title
                meta_description
                open_graph_image {
                  data {
                    id
                    attributes {
                      cdnURL
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
