import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import { Helmet } from "react-helmet";

export const SeoBlock = ({
  title,
  description,
  imageUrl,
  favicon,
  lang,
  author,
}) => {
  const { language } = useI18next();
  return (
    <Helmet
      htmlAttributes={{
        lang: lang ?? language,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `viewport`,
          content: `width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0`,
        },
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: imageUrl,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          property: `twitter:image`,
          content: imageUrl,
        },
      ]}
      link={[
        {
          rel: "icon",
          type: "image/png",
          href: favicon,
        },
        {
          rel: "apple-touch-icon",
          href: favicon,
        },
      ]}
    />
  );
};
