import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import AyanaGroupLogo from "./Icons/AyanaGroupLogo";

export const SplashScreen = () => {
  const [pageLoad, setPageLoad] = useState(false);
  useEffect(() => {
    if (!pageLoad) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
    const load = setTimeout(() => setPageLoad(true), 2500);
    return () => clearTimeout(load);
  }, [pageLoad]);

  return (
    <AnimatePresence>
      {!pageLoad && (
        <motion.div
          className="fixed inset-0 h-full bg-black z-90 flex justify-center"
          key={"loading"}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: "easeInOut", duration: 1.25 }}>
          <div className="w-[116px] h-90 mb-55 absolute top-1/4 -mt-40">
            <AyanaGroupLogo />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
