import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "../../CommonIcon/Icon";
import { useViewPortHeight } from "hooks/useViewPortHeight";
import { ArtDirectedImage } from "../ArtDirectedImage";

const variants = {
  enter: {
    opacity: 0,
    zIndex: 1,
  },
  center: {
    opacity: 1,
    zIndex: 1,
  },
  exit: {
    opacity: 0,
    zIndex: 0,
  },
};

const imageData = {
  title: {
    0: "A Place of Refuge",
    1: "A Refuge of Spellbinding Wonder",
    2: "A Refuge of Urban Quietude",
    3: "Unique Journeys on the Open Sea",
  },
  subtitle: {
    0: "AYANA Resort Bali",
    1: "AYANA Komodo Waecicu Beach",
    2: "AYANA Midplaza, JAKARTA",
    3: "AYANA Lako di’a",
  },
};

export const FadingCarousel = ({ images }) => {
  const [index, setIndex] = useState(0);

  const goPrevious = () => {
    const newIndex = index === 0 ? images.length - 1 : index - 1;
    setIndex(newIndex);
  };

  const goNext = () => {
    setIndex((index + 1) % images.length);
  };

  const panAnimate = (_, { offset }) => {
    if (offset.x > 0) {
      goPrevious();
    } else {
      goNext();
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      goNext();
    }, 8000);

    return () => clearTimeout(timeout);
  }, [index]);

  const height = useViewPortHeight();
  const heightStyle = height ? { height } : {};

  return (
    <div
      className="relative overflow-hidden h-screen transition-all"
      style={heightStyle}>
      <AnimatePresence initial={false}>
        <motion.div
          className="absolute w-full"
          key={index}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            opacity: { duration: 2.5 },
            type: "tween",
            ease: "easeIn",
          }}
          onPan={panAnimate}>
          <div className="relative" style={heightStyle}>
            <div className="absolute z-50 w-full top-1/4 flex justify-center -mt-40 opacity-90 items-center flex-col gap-20">
              <Icon
                name="ayana-landing-white"
                color="text-white"
                fontSize="text-90"
              />
              <h1 className="text-white text-24">{imageData.title[index]}</h1>
            </div>
            <div
              className="absolute inset-0 w-screen min-h-screen bg-landing-banner mix-blend-multiply z-2"
              style={heightStyle}
            />
            <ArtDirectedImage
              className="h-screen"
              style={heightStyle}
              image={images[index]}
              loading="eager"
            />
          </div>
        </motion.div>
      </AnimatePresence>
      <div className="absolute z-50 flex flex-col w-full gap-12 bottom-50">
        <p className="flex justify-center text-white text-16 leading-24">
          {imageData.subtitle[index]}
        </p>
        <div className="flex items-center justify-center gap-30">
          <button onClick={goPrevious}>
            <Icon name="left-arrow-thin" color="text-white" />
          </button>
          <p className="text-white font-cormorant text-16 leading-24">{`${
            index + 1
          } / ${images.length}`}</p>
          <button onClick={goNext}>
            <Icon name="right-arrow-thin" color="text-white" />
          </button>
        </div>
      </div>
    </div>
  );
};
